import { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as yup from "yup";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import SportFormat from "../../../components/cards/pricingCard";
import FeaturesCard from "../../../components/cards/featuresCard";
import TimingsCard from "../../../components/cards/timingsCard";
import {
    FormikInput,
    FormikSelect,
    FormikSwitch,
    FormikDate,
} from "../../../components/formik-components";
import { useNavigate, useLocation } from "react-router-dom";
import {
    DayOfWeekEnum,
    respEnum,
    SportFeatureTypeEnum,
} from "../../../constants/appConstant";
import Common from "../../../helper/common";

const formValidations = yup.object({
    sportId: yup.number().required("Sport is Required"),
    name: yup
        .string()
        .min(5, "Name must be at least 5 characters long")
        .max(30, "Name must be at most 30 characters long")
        .required("Name is required"),
    sportType: yup.number().required("Sport Type is Required"),

});

const formInitialValue = {
    sameasClubTiming: true,
    isBookableOnline: true,
    isactive: true,
};

export default function AddCourt() {
    const [sports, setSports] = useState([]);
    const [sportFeatures, setSportFeatures] = useState([]);
    const [sportFormats, setSportFormats] = useState([]);
    const [sportTypes, setSportTypes] = useState([]);
    const [timings, setTimings] = useState(
        Common.Utility.enumToArray(DayOfWeekEnum).map((day) => ({
            ...day,
            isOn: false,
            dayOfweek: day.id,
            openingTime: "",
            closingTime: "",
        }))
    );
    const [sportPlayerOptions, setSportPlayerOptions] = useState([]);
    const formikRef = useRef();
    //   const router = useRouter();
    const navigate = useNavigate();
    const [id, setId] = useState();
    const location = useLocation();

    useEffect(() => {
        GetSports();
        const params = new URLSearchParams(location.search);
        const fetchedId = params.get("id");
        if (fetchedId) {
            setId(fetchedId);
        }
    }, []);

    useEffect(() => {
        if (id && sports?.length) {
            GetCourtDetails();
        }
    }, [id, sports]);

    const GetCourtDetails = async () => {
        let response = await Common.ApiService.getInstance().request(
            `GetCourtDetails?id=${id}`
        );
        let d = response?.data[0];
        global.log(JSON.stringify(d));

        if (d && formikRef.current) {
            formikRef.current.setFieldValue("sportId", d.sportId);
            await Common.delay();
            ChangeSport(d.sportId, formikRef.current, d);
        }

        formikRef.current.setFieldValue("desc", d.desc);
        formikRef.current.setFieldValue("isactive", d.isactive);
        formikRef.current.setFieldValue("isBookableOnline", d.isBookableOnline);
        formikRef.current.setFieldValue("name", d.name);
        formikRef.current.setFieldValue("sameasClubTiming", d.sameasClubTiming);
        formikRef.current.setFieldValue("size", d.size);

        // let _data = {
        //   ...d,
        //   club: {
        //     ...d.club,
        //     thumbnail: clubThumbnail,
        //   },
        //   groupedResults,
        // };
        // setBooking(_data);
        // setLoading(false);
    };

    const ChangeSport = (val, formikProps, court) => {
        if (court) {
            global.log("Court", court);
        }

        formikProps.setFieldValue("sportType", undefined);

        if (val != null) {
            let _sport = sports.find((x) => x.id == val);

            if (_sport) {
                let sfeatures = _sport.sportFeatures
                    ?.filter((item) => item.type == SportFeatureTypeEnum.Feature)
                    .map((sf) => ({
                        ...sf,
                        sportFeatureId: sf.id,
                    }));

                let sTypes = _sport.sportFeatures?.filter(
                    (item) => item.type == SportFeatureTypeEnum.Type
                );

                let sFormats = _sport.sportFormats?.map((item) => ({
                    ...item,
                    sportFormatId: item.id,
                }));

                let splayerOptions = _sport.sportFeatures
                    ?.filter((item) => item.type == SportFeatureTypeEnum.No_of_Players)
                    .map((item) => ({
                        ...item,
                        name: `${item.name}`,
                        sportFormatId: item.id,
                        sportFeatureId: item.id,
                        isSelected: court?.features?.some(
                            (feature) => feature.sportFeatureId == item.id
                        ),
                    }));

                // Only set state if values have changed to prevent re-renders
                setSportFeatures(
                    court
                        ? sfeatures.map((item) => ({
                            ...item,
                            isSelected: court.features.some(
                                (feature) => feature.sportFeatureId == item.id
                            ),
                        }))
                        : sfeatures
                );
                setSportTypes(sTypes);
                setSportFormats(
                    court
                        ? sFormats.map((item) => {
                            let format = court.formats.find(
                                (format) => format.sportFormatId == item.sportFormatId
                            );
                            return {
                                ...item,
                                isSelected: format ? true : false,
                                charges: format?.charges,
                            };
                        })
                        : sFormats
                );

                let sportTypeId = court?.features?.find(
                    (item) => item.type == SportFeatureTypeEnum.Type
                ).sportFeatureId;
                formikRef.current.setFieldValue("sportType", sportTypeId);

                setSportPlayerOptions(splayerOptions);
                if (court && court.timings?.length) {
                    setTimings(
                        court.timings.map((item) => ({
                            ...item,
                            openingTime: item.openingTime
                                ? getTiming(item.openingTime)
                                : item.openingTime,
                            closingTime: item.closingTime
                                ? getTiming(item.closingTime)
                                : item.closingTime,
                        }))
                    );
                }
            }
        } else if (!val) {
            setSportFeatures([]);
            setSportTypes([]);
            setSportFormats([]);
            setSportPlayerOptions([]);
        }
    };

    const getTiming = (val) => {
        let _timing = val.split(":");
        return `${_timing[0]}:${_timing[1]}`;
    };

    const GetSports = async () => {
        const response = await Common.ApiService.getInstance().request("GetSports");

        const sportsData = [
            { id: null, name: "Select Sport" },
            ...(response?.data ?? []),
        ];
        setSports(sportsData);
    };
    const onSubmitData = async (d, action) => {
        let data = {
            ...d,
            id,
        };

        data.features.push({
            name: "",
            noofplayers: null,
            sportFeatureId: data.sportType,
            isSelected: true,
        });

        if (!data.features.length) {
            Common.showToast("Add at least one feature", "Error");
            return;
        }

        if (data.playerOptions && data.playerOptions.length > 0) {
            data.playerOptions.forEach((option) => {
                data.features.push({
                    name: option.name,
                    noofplayers: option.noofplayers,
                    sportFeatureId: option.sportFeatureId,
                    isSelected: true,
                });
            });
        } else {
            Common.showToast("Add at least one Player Option", "Error");
            return;
        }

        if (!data.formats.length) {
            Common.showToast("Add at least one format", "Error");
            return;
        }

        const invalidFormat = data.formats.find(
            (format) => format.charges === undefined || format.charges <= 0
        );
        if (invalidFormat) {
            if (invalidFormat.charges === undefined) {
                Common.showToast(
                    `Please enter a price for ${invalidFormat.name}`,
                    "Error"
                );
            } else if (invalidFormat.charges <= 0) {
                Common.showToast(
                    `Price must be greater than 0 for ${invalidFormat.name}`,
                    "Error"
                );
            }

            return;
        }

        if (!data.sameasClubTiming && !data.timings.length) {
            Common.showToast(`Select at least 1 time slot`, `Error`);

            return;
        }

        
        if (!data.sameasClubTiming && data.timings.length) {
            var isOpeningTimeValid;
            var isClosingTimeValid;
            var validTime;
            let invalidTime = data.timings.find((item) => {
                if (!item.isOn) return false;
                validTime = item.openingTime < item.closingTime;
                isOpeningTimeValid = Common.Utility.CheckTime(item.openingTime);
                isClosingTimeValid = Common.Utility.CheckTime(item.closingTime);
                return !validTime || !isOpeningTimeValid || !isClosingTimeValid;
            });

            if (invalidTime) {
                if (!invalidTime.openingTime) {
                    Common.showToast(
                        `Provide opening time for ${Common.Utility.getKeyByValue(
                            DayOfWeekEnum,
                            invalidTime.dayOfweek
                        )}`,
                        "Error"
                    );
                    return;
                }
                if (!isOpeningTimeValid) {
                    Common.showToast(
                        `Invalid time format for opening time should be in 00, 30, or 23:59 format.`,
                        "Error"
                    );
                    return;
                }

                if (!invalidTime.closingTime) {
                    Common.showToast(
                        `Provide closing time for ${Common.Utility.getKeyByValue(
                            DayOfWeekEnum,
                            invalidTime.dayOfweek
                        )}`,
                        "Error"
                    );
                    return;
                }
                if (!isClosingTimeValid) {
                    Common.showToast(
                        `Invalid time format for closing time should be in 00, 30, or 23:59 format.`,
                        "Error"
                    );
                    return;
                }
                if (!isOpeningTimeValid) {
                    Common.showToast(
                        `Invalid time format for opening time should be in 00, 30, or 23:59 format.`,
                        "Error"
                    );
                    return;
                }
                if (!validTime) {
                    Common.showToast(
                        `Closing TIme should be greater than Opening Time  of ${Common.Utility.getKeyByValue(
                            DayOfWeekEnum,
                            invalidTime.dayOfweek
                        )}`,
                        "Error"
                    );
                    return;
                }
            }

            data.timings = data.timings.map((item) => ({
                ...item,
                openingTime: item.isOn
                    ? Common.Utility.formatTimeToCSharp(item.openingTime)
                    : null,
                closingTime: item.isOn
                    ? Common.Utility.formatTimeToCSharp(item.closingTime)
                    : null,
            }));
        }

        if (data.sameasClubTiming) {
            data.timings = null;
        }

        let response = await Common.ApiService.getInstance().request(
            "AddUpdateCourt",
            data,
            "POST"
        );
        action.setSubmitting(false);
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/courts");
            }, 500);
        }
    };

    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="space-y-6 mb-5">
                            <Card title={`${id > 0 ? "Edit" : "Add"} Court`}>
                                <div className="md:grid md:grid-cols-3  gap-4 p-4">
                                    <FormikSelect
                                        formikProps={formikProps}
                                        name={"sportId"}
                                        data={sports}
                                        placeholder={"Select Sport"}
                                        label={"Sport"}
                                        onChange={(val) => ChangeSport(val, formikProps)}
                                    />

                                    <div className="flex flex-col gap-2">
                                        <FormikDate
                                            formikProps={formikProps}
                                            name={"name"}
                                            placeholder={"Court Name"}
                                            type={"text"}
                                            label={"Court Name"}
                                        />
                                    </div>

                                    <div className="flex flex-col gap-2">
                                        <FormikInput
                                            formikProps={formikProps}
                                            name={"desc"}
                                            placeholder={"Description"}
                                            label={"Description"}
                                            type={"text"}
                                        />
                                    </div>

                                    <div className="md:grid md:grid-cols-5  col-span-3 gap-6 space-y-6 md:space-y-0">
                                        <div className="col-span-1">
                                            <FormikSelect
                                                formikProps={formikProps}
                                                name={"sportType"}
                                                data={sportTypes}
                                                placeholder={"Sport Type"}
                                                label={"Sport Type"}
                                            />
                                        </div>

                                        <div className="col-span-1">
                                            <FormikInput
                                                formikProps={formikProps}
                                                name={"size"}
                                                placeholder={"100 x 120"}
                                                label={"Size"}
                                                type={"text"}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"sameasClubTiming"}
                                                label={"Same as Club Timing"}
                                                disabled={true}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isBookableOnline"}
                                                label={"Is Online Bookable"}
                                            />
                                        </div>
                                        <div className="col-span-1">
                                            <FormikSwitch
                                                formikProps={formikProps}
                                                name={"isactive"}
                                                label={"Is Active"}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="md:grid md:grid-cols-4  gap-6 mt-6 space-y-6 md:space-y-0">
                            <div className="col-span-2">
                                {formikProps.values.sportId && (
                                    <SportFormat
                                        formikProps={formikProps}
                                        data={sportFormats}
                                        name={"formats"}
                                    />
                                )}
                            </div>
                            {formikProps.values.sportId && (
                                <FeaturesCard
                                    formikProps={formikProps}
                                    data={sportFeatures}
                                    name={"features"}
                                    title={"Features"}
                                />
                            )}
                            {formikProps.values.sportId && (
                                <FeaturesCard
                                    formikProps={formikProps}
                                    data={sportPlayerOptions}
                                    name={"playerOptions"}
                                    title={"Player Options"}
                                />
                            )}

                            <div className="col-span-2">
                                {!formikProps.values.sameasClubTiming && (
                                    <TimingsCard
                                        formikProps={formikProps}
                                        name={"timings"}
                                        data={timings}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="grid grid-cols-6 mt-5">
                            <Button
                                type={"submit"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    formikProps.handleSubmit();
                                }}
                                disabled={formikProps.isSubmitting}
                            >
                                {formikProps.isSubmitting ? "Saving..." : "Save"}
                            </Button>
                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
