import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useNavigate } from "react-router-dom";
import Common from "../../../helper/common";
import { AppSearchSectionSelect } from "../../../components/appComponents";
import { respEnum } from "../../../constants/appConstant";

const localizer = momentLocalizer(moment);

const BookingCalendar = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [courtList, setCourtList] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState(null);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());

  useEffect(() => {
    getCourts();
  }, []);

  useEffect(() => {
    if (selectedCourt) {
      fetchBookings();
    }
  }, [selectedCourt, startDate, endDate]);

  const getCourts = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetSportWiseCourtddl"
    );

    if (response?.status == respEnum.Success) {
      
      setCourtList([{ id: null, name: "Select Court" },
        ...(response?.data ?? []),
      ]);
    }
  };

  const fetchBookings = async () => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const formattedStartDate = startDateObj.toISOString();
    const formattedEndDate = endDateObj.toISOString();

    const response = await Common.ApiService.getInstance().request(
      `GetCourtBookings?courtId=${selectedCourt}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`
    );

    if (response.status === respEnum.Success) {
      const bookings =
        response?.data?.map((booking) => ({
          ...booking,
          start: new Date(booking.start),
          end: new Date(booking.end),
        })) ?? [];

      setEvents(bookings);
    }
  };

  const handleCourtChange = (courtId) => {
    setSelectedCourt(courtId);
  };

  const handleNavigate = async (newDate, view) => {
    
    let startOfPeriod, endOfPeriod;

    if (view === "month") {
      startOfPeriod = moment(newDate).startOf("month").toDate();
      endOfPeriod = moment(newDate).endOf("month").toDate();
    } else if (view === "week") {
      startOfPeriod = moment(newDate).startOf("week").toDate();
      endOfPeriod = moment(newDate).endOf("week").toDate();
    } else if (view === "day") {
      startOfPeriod = moment(newDate).startOf("day").toDate();
      endOfPeriod = moment(newDate).endOf("day").toDate();
    }

    setStartDate(startOfPeriod);
    setEndDate(endOfPeriod);
    if (selectedCourt) {
      await fetchBookings();
    }
  };

  const handleSelectEvent = (event) => {
    navigate(`/app/bookingDetails?id=${event.id}`);
  };

  return (
    <div className="min-h-screen flex flex-col items-centerx justify-center">
      <div className="md:grid md:grid-cols-4 gap-3 space-y-6 mb-5">
        <div className="flex flex-col gap-3">
          <AppSearchSectionSelect
            name={"courtId"}
            label={"Court"}
            data={courtList}
            onChange={(e) => handleCourtChange(e.value)}
          />
        </div>
      </div>

      <div className="bg-white shadow-md p-6 rounded-lg w-full">
        <h2 className="text-2xl font-bold mb-4">Court Wise Bookings</h2>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          selectable
          style={{ height: 700 }}
          views={["month", "week", "day"]}
          defaultView="week"
          step={30}
          timeslots={2}
          defaultDate={new Date()}
          onNavigate={handleNavigate}
          onSelectEvent={handleSelectEvent}
          eventPropGetter={(event) => ({
            style: {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
            },
          })}
        />
      </div>
    </div>
  );
};

export default BookingCalendar;
