import React, { useEffect, useState } from "react";
import ThemeButton from "./theme-button";
import ProfileInfo from "./profile-info";
import VerticalHeader from "./vertical-header";
import HorizontalHeader from "./horizontal-header";
import Inbox from "./inbox";
import HorizontalMenu from "./horizontal-menu";
import NotificationMessage from "./notification-message";
import MobileMenuHandler from "./mobile-menu-handler";
import ClassicHeader from "./layout/classic-header";
import FullScreen from "./full-screen";
import { cn } from "../../../helper/utilityHelper";
import { useSidebar, useThemeStore } from "../../../store";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { AppSelect } from "../../../components/appComponents";
import common from "../../../helper/common";

const NavTools = ({ isDesktop, isMobile, sidebarType }) => {
  const [myClubs, setMyClubs] = useState([]);
  const [myClubId, setMyClubId] = useState();

  useEffect(() => {
    (async () => {
      setMyClubs(global.user.myClubs);
      setMyClubId(global.user.defaultClubId);
    })();
  }, []);

  useEffect(() => {
    common.setUser({ ...global.user, defaultClubId: myClubId });
    // global.user.defaultClubId = myClubId;
    global.log("defaultClubId", global.user.defaultClubId);
  }, [myClubId]);

  const onClubChange = async (id) => {
    setMyClubId(id);
    let resp = await common.ApiService.getInstance().request(
      `ChangeDefaultClub?clubId=${id}`,
      {},
      "POST"
    );
    common.SweetAlert.alert(resp.message, "success");
    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  return (
    <div className="nav-tools flex items-center  gap-2">
      {/* <div className="md:grid md:grid-cols-4 gap-3 space-y-6 mb-5"> */}
      {myClubId && (
        <div className="flex flex-col gap-3">
          <AppSelect
            placeholder={"Select Club"}
            data={myClubs}
            value={myClubId}
            className={"w-300"}
            onChange={(e) => onClubChange(e)}
          />
        </div>
      )}
      {/* </div> */}
      {/* {isDesktop && <Language />} */}
      {isDesktop && <FullScreen />}
      {/* <ThemeButton /> */}
      {/* <Inbox /> */}
      {/* <NotificationMessage /> */}
      <div className=" pl-2">
        <ProfileInfo />
      </div>
      <div></div>
      {!isDesktop && sidebarType !== "module" && <MobileMenuHandler />}
    </div>
  );
};

const Header = ({ handleOpenSearch }) => {
  const { collapsed, sidebarType, setCollapsed, subMenu, setSidebarType } =
    useSidebar();
  const { layout, navbarType, setLayout } = useThemeStore();

  const isDesktop = useMediaQuery("(min-width: 1280px)");

  const isMobile = useMediaQuery("(min-width: 768px)");

  // set header style to classic if isDesktop
  React.useEffect(() => {
    if (!isDesktop && layout === "horizontal") {
      setSidebarType("classic");
    }
  }, [isDesktop]);

  // if horizontal layout
  if (layout === "horizontal" && navbarType !== "hidden") {
    return (
      <ClassicHeader
        className={cn(" ", {
          "sticky top-0 z-50": navbarType === "sticky",
        })}
      >
        <div className="w-full bg-card/90 backdrop-blur-lg md:px-6 px-[15px] py-3 border-b">
          <div className="flex justify-between items-center h-full">
            <HorizontalHeader handleOpenSearch={handleOpenSearch} />
            <NavTools
              isDesktop={isDesktop}
              isMobile={isMobile}
              sidebarType={sidebarType}
            />
          </div>
        </div>
        {isDesktop && (
          <div className=" bg-card bg-card/90 backdrop-blur-lg  w-full px-6  shadow-md">
            <HorizontalMenu />
          </div>
        )}
      </ClassicHeader>
    );
  }
  if (layout === "semibox" && navbarType !== "hidden") {
    return (
      <ClassicHeader
        className={cn("  has-sticky-header rounded-md   ", {
          "xl:ml-[72px]": collapsed,
          "xl:ml-[272px]": !collapsed,
          "sticky top-6": navbarType === "sticky",
        })}
      >
        <div className={cn("xl:mx-20 mx-4")}>
          <div className="w-full bg-card/90 backdrop-blur-lg md:px-6 px-[15px] py-3 rounded-md my-6 shadow-md border-b">
            <div className="flex justify-between items-center h-full">
              <VerticalHeader
                sidebarType={sidebarType}
                handleOpenSearch={handleOpenSearch}
              />
              <NavTools
                isDesktop={isDesktop}
                isMobile={isMobile}
                sidebarType={sidebarType}
              />
            </div>
          </div>
        </div>
      </ClassicHeader>
    );
  }
  if (
    sidebarType !== "module" &&
    navbarType !== "floating" &&
    navbarType !== "hidden"
  ) {
    return (
      <ClassicHeader
        className={cn("", {
          "xl:ml-[248px]": !collapsed,
          "xl:ml-[72px]": collapsed,
          "sticky top-0": navbarType === "sticky",
        })}
      >
        <div className="w-full bg-card/90 backdrop-blur-lg md:px-6 px-[15px] py-3 border-b">
          <div className="flex justify-between items-center h-full ">
            <VerticalHeader
              sidebarType={sidebarType}
              handleOpenSearch={handleOpenSearch}
            />
            <NavTools
              isDesktop={isDesktop}
              isMobile={isMobile}
              sidebarType={sidebarType}
            />
          </div>
        </div>
      </ClassicHeader>
    );
  }
  if (navbarType === "hidden") {
    return null;
  }
  if (navbarType === "floating") {
    return (
      <ClassicHeader
        className={cn("  has-sticky-header rounded-md sticky top-6  px-6  ", {
          "ml-[72px]": collapsed,
          "xl:ml-[300px] ": !collapsed && sidebarType === "module",
          "xl:ml-[248px] ": !collapsed && sidebarType !== "module",
        })}
      >
        <div className="w-full bg-card/90 backdrop-blur-lg md:px-6 px-[15px] py-3 rounded-md my-6 shadow-md border-b">
          <div className="flex justify-between items-center h-full">
            <VerticalHeader
              sidebarType={sidebarType}
              handleOpenSearch={handleOpenSearch}
            />
            <NavTools
              isDesktop={isDesktop}
              isMobile={isMobile}
              sidebarType={sidebarType}
            />
          </div>
        </div>
      </ClassicHeader>
    );
  }

  return (
    <ClassicHeader
      className={cn("", {
        "xl:ml-[300px]": !collapsed,
        "xl:ml-[72px]": collapsed,
        "sticky top-0": navbarType === "sticky",
      })}
    >
      <div className="w-full bg-card/90 backdrop-blur-lg md:px-6 px-[15px] py-3 border-b">
        <div className="flex justify-between items-center h-full">
          <VerticalHeader
            sidebarType={sidebarType}
            handleOpenSearch={handleOpenSearch}
          />
          <NavTools
            isDesktop={isDesktop}
            isMobile={isMobile}
            sidebarType={sidebarType}
          />
        </div>
      </div>
    </ClassicHeader>
  );
};

export default Header;
