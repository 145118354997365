import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "@iconify/react";
import moment from "moment";
import { loader } from "react-global-loader";
import { useLocation, useNavigate } from "react-router-dom";
import Common from "../../helper/common";
import { Button } from "../../components/ui/button";
import {
  PaymentTypeEnum,
  TransactionLogStatusEnum,
  TransactionLogTypeEnum,
} from "../../constants/appConstant";
import AdvanceTable from "../../components/advanceTable";

const Transactions = () => {
  const [loading, setLoading] = useState(true);
  let location = useLocation();

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [data, setData] = useState({});

  const navigate = useNavigate();
  const [paramStatus, setParamStatus] = useState();

  useEffect(() => {
    let status = +params.get("status");
    setParamStatus(status);
    getData(1);
  }, [params]);

  const getData = async (
    pageNumber,
    searchTerm = "",
    recordsToDisplay = null
  ) => {
    setLoading(true);
    let status = +params.get("status");

    const response = await Common.ApiService.getInstance().request(
      "GetClubTransactions",
      {
        pageNumber: pageNumber,
        q: searchTerm,
        recordsToDisplay,
        status,
      },
      "Post",
      false
    );
    if (response?.data) {
      let obj = {
        ...response.data[0],
        data: response.data[0].data.map((item) => ({
          ...item,
          bookingDate: `${moment(item.startDateTime).format("DD MMM yyyy")}`,
          // paymentTypeName: Common.Utility.getKeyByValue(
          //   PaymentTypeEnum,
          //   item.paymentType
          // ),
          paymentStatusName: Common.Utility.getKeyByValue(
            TransactionLogStatusEnum,
            item.status
          ),
          transactionTypeName: Common.Utility.getKeyByValue(
            TransactionLogTypeEnum,
            item.transactionType
          ),
          isByClub: item.isByClub ? "Yes" : "No",
        })),
      };

      setData(obj);
      setLoading(false);
    }
  };
  const MarkTransactionRefunded = async (id) => {
    const isConfirmed = await Common.SweetAlert.confirmation(
      "Are you sure you want to Mark Payment as Refunded?",
      "Payment Refunded"
    );
    if (isConfirmed) {
      let resp = await Common.ApiService.getInstance().request(
        `MarkTransactionAsRefunded?id=${id}`,
        {},
        "Post"
      );
      Common.SweetAlert.alert(resp.message, "success");
      setTimeout(() => {
        getData();
      }, 500);
    }
  };

  const _addUpdateTrigger = () => (
    <Button size="icon" variant="outline" color="secondary" className="h-7 w-7">
      <Icon icon="heroicons:pencil" className="h-4 w-4" />
    </Button>
  );

  const actions = (status) =>
    [
      {
        type: "view",
        onClick: (val) => {
          navigate(`/app/bookingDetails?id=${val.bookingId}`);
        },
      },
      status === TransactionLogStatusEnum.Refundable && {
        type: "custom",
        button: (val) => (
          <button
            className="text-xs px-1 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 inline-block"
            onClick={() => MarkTransactionRefunded(val.id)}
          >
            Mark Refund
          </button>
        ),
      },
    ].filter(Boolean);

  let columns = [
    { title: "Basket Id", type: "text", key: "id" },
    { title: "Player Name", type: "text", key: "playerName" },
    { title: "Transaction Type", type: "text", key: "transactionTypeName" },
    { title: "Payment Type", type: "text", key: "paymentTypeName" },
    { title: "Payment Account", type: "text", key: "clubAccountName" },
    { title: "Player Phone #", type: "text", key: "playerCellNo" },
    { title: "Status", type: "text", key: "paymentStatusName" },
    { title: "Amount", type: "text", key: "amount" },
    { title: "Is By Club", type: "text", key: "isByClub" },
    { title: "Court", type: "text", key: "courtName" },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {data?.data && (
        <AdvanceTable
          title={`Payment ${Common.Utility.getKeyByValue(
            TransactionLogStatusEnum,
            paramStatus
          )}`}
          columns={columns}
          data={data}
          getData={getData}
          showPagination
          actionButtons={actions(paramStatus)}
          updateTrigger={_addUpdateTrigger} // Example function
          showExportoptions
        />
      )}
    </>
  );
};

export default Transactions;
