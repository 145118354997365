import React, { useEffect, useState } from "react";
import BookingDetailSidebar from "./components/detail-sidebar";
import BooingFurthurDetails from "./components/detail-table";
import { Icon } from "@iconify/react";
import { Formik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useMediaQuery } from "../../../hooks";
import { cn } from "../../../helper/utilityHelper";
import {
  FormikInput,
  FormikSearchSectionSelect,
  FormikSelect,
  FormikSingleFileUpload,
  FormikDate,
} from "../../../components/formik-components";
import { Button } from "../../../components/ui/button";
import DialogModal from "../../../components/partials/modal/dialogModal";
import AppConstant, {
  PaymentTypeEnum,
  RoleEnum,
  IntMinLevel,
  DateminFormat,
  respEnum,
  ToCSharpFormat,
} from "../../../constants/appConstant";
import Common from "../../../helper/common";
import {
  BreadcrumbItem,
  Breadcrumbs,
} from "../../../components/ui/breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { CardContent, CardHeader, Card } from "../../../components/ui/card";
import { useMenu } from "../../../contexts/menuContext";

const BooingDetails = () => {
  const location = useLocation();
  const isDesktop = useMediaQuery("(max-width: 1280px)");
  const [openEdit, setOpenEdit] = useState(false);
  const [booking, setBooking] = useState({});
  const [showSidebar, setShowSidebar] = useState(true);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [courtList, setCourtList] = useState([]);
  const [id, setId] = useState();
  const navigate = useNavigate();
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [showReschedule, setShowReschedule] = useState(false);
  const { setIsMenuVisible } = useMenu();

  useEffect(() => {
    setIsMenuVisible(false);
    const params = new URLSearchParams(location.search);
    setId(params.get("id"));
    if (id) {
      getBookingDetails();
    }
    getClubPaymentTypes();
    return () => {
      setIsMenuVisible(true);
    };
  }, [id]);

  useEffect(() => {
    if (showReschedule) {
      getCourts();
    }
  }, [showReschedule]);

  const formValidations = yup.object({
    clubAccountId: yup.object().required("Payment Account Required"),
    amount: yup
      .number()
      .required("Enter Amount")
      .min(IntMinLevel, "Amount can't be negative or zero")
      .max(
        booking?.remainingAmount,
        `Amount must be less ${booking?.remainingAmount}`
      ),
  });

  const formRescheduleValidations = yup.object({
    courtId: yup.object().required("Court is Requied"),
    bookingDate: yup
      .date()
      .required("Booking Date is Requied")
      .min(Common.Utility.today, "Date should be greater than previous date "),
    startDateTime: yup
      .string()
      .required("Start Time is required")
      .test(
        "is-valid-time",
        "Start Time format should be in 00, 30 or 23:59 format",
        (value) => Common.Utility.CheckTime(value)
      )
      .matches(
        /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
        "Invalid time format (HH:mm)"
      ),

    endDateTime: yup
      .string()
      .required("End time is required")
      .test(
        "is-valid-time",
        "End Time format should be in 00, 30 23:59 format",
        (value) => Common.Utility.CheckTime(value)
      )
      .matches(
        /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
        "Invalid time format (HH:mm)"
      ),
  });

  const handleEditSheetOpen = () => {
    setOpenEdit(!openEdit);
  };

  const getCourts = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetSportWiseCourtddl"
    );

    if (response?.status == respEnum.Success) {
      setCourtList(response?.data ?? []);
    }
  };

  const AddPayment = async (d) => {
    setShowAddPayment(false);

    if (d.files && d.files.filter((item) => !item.imageUrl).length) {
      d.attachment = (await Common.Utility.handleFileUpload(d.files))[0].photo;
      d.files = null;
    }
    let data = {
      ...d,
      bookingId: booking?.id,
      clubAccountId: d.clubAccountId?.value,
    };

    if (booking?.remainingAmount < data.amount) {
      toast.error(
        `Received amount must be less then ${booking?.remainingAmount} Rs`
      );
      return;
    }

    let resp = await Common.ApiService.getInstance().request(
      "AddBookingPayment",
      data,
      "POST"
    );
    Common.SweetAlert.alert(resp.message, "success");
    setTimeout(() => {
      getBookingDetails();
    }, 500);
  };

  const Reschedule = async (d) => {
    let data = {
      ...d,
      bookingId: booking?.id,
      courtId: d.courtId.value,
      newStartDateTime: Common.moment(
        `${d.bookingDate} ${d.startDateTime}`
      ).format(ToCSharpFormat),
      newEndDateTime: Common.moment(`${d.bookingDate} ${d.endDateTime}`).format(
        ToCSharpFormat
      ),
    };

    if (data.newStartDateTime >= data.newEndDateTime) {
      toast.error(`End time must be greater`);
      return;
    }

    const now = dayjs();
    if (
      dayjs(data.newStartDateTime).isBefore(now) ||
      dayjs(data.newEndDateTime).isBefore(now)
    ) {
      toast.error(`Start Time and End Time must not be in the past`);
      return;
    }

    if (
      data.newStartDateTime == booking.startDateTime ||
      data.newEndDateTime == booking.endDateTime
    ) {
      toast.error(`Start and End datetime must be different`);
      return;
    }

    const originalDuration = dayjs(booking?.endDateTime).diff(
      dayjs(booking?.startDateTime),
      "minute"
    );
    const newDuration = dayjs(data.newEndDateTime).diff(
      dayjs(data.newStartDateTime),
      "minute"
    );

    if (
      newDuration != originalDuration - 1 &&
      newDuration != originalDuration
    ) {
      toast.error(`The duration must be (${originalDuration} minutes)`);

      return;
    }

    setShowReschedule(false);
    let resp = await Common.ApiService.getInstance().request(
      "BookingRescheduling",
      data,
      "POST"
    );
    if (resp.status == respEnum.Success) {
      Common.SweetAlert.alert(resp.message, "success");
      setTimeout(() => {
        getBookingDetails();
      }, 500);
    }
  };

  const getBookingDetails = async () => {
    let response = await Common.ApiService.getInstance().request(
      `BookingDetails?id=${id}`
    );
    if (response.status == respEnum.Success && response?.data) {
      let d = response?.data?.[0];

      const groupedResults = Array.isArray(d.result)
        ? d.result.reduce((acc, result) => {
            if (!acc[result.side]) {
              acc[result.side] = [];
            }
            acc[result.side].push(result);
            return acc;
          }, {})
        : {};

      const clubThumbnail =
        d.club?.images?.[0]?.path || "default-thumbnail.jpg";

      let _data = {
        ...d,
        club: {
          ...d.club,
          thumbnail: clubThumbnail,
        },
        groupedResults,
      };
      setBooking(_data);
    }
  };

  const getClubPaymentTypes = async () => {
    const response = await Common.ApiService.getInstance().request(
      "GetClubPaymentTypes"
    );

    if (response?.status == respEnum.Success) {
      setPaymentTypeList(response?.data ?? []);
    }
  };

  const _renderAddPayment = () => (
    <div>
      <Formik
        onSubmit={AddPayment}
        validationSchema={formValidations}
        initialValues={{
          amount: booking?.remainingAmount,
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <>
            <div className="mb-3">
              {/* <FormikSelect
                formikProps={formikProps}
                name="paymentType"
                placeholder="Select"
                label="Payment Type"
                data={Common.Utility.enumToArray(PaymentTypeEnum)}
              /> */}

              <FormikSearchSectionSelect
                formikProps={formikProps}
                name={"clubAccountId"}
                label={"Payment Account"}
                data={paymentTypeList}
              />
            </div>

            <div>
              <FormikInput
                formikProps={formikProps}
                name="amount"
                placeholder="Received Amount"
                label="Received Amount"
                type={"number"}
                min={IntMinLevel}
                onChange={(value) => {
                  if (value < IntMinLevel && value != "") {
                    formikProps.setFieldValue("amount", IntMinLevel);
                  } else {
                    formikProps.setFieldValue("amount", value);
                  }
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <FormikSingleFileUpload
                formikProps={formikProps}
                uploadMainTxt="Upload Receipt"
                uploadTxt="Max size 2mb"
                name="attachment"
              />
            </div>
            {/* <div>
              <label style={{ display: 'block', marginBottom: '5px' }}>Remaining Amount:</label>
              <div style={{ fontSize: '18px', fontWeight: 'bold' }}>{booking?.remainingAmount}</div>
            </div> */}

            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <Button
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }}
                className="shadow-md mr-3"
              >
                {" "}
                Add Payment
              </Button>

              <Button
                color="secondary"
                className="secondary"
                onClick={() => setShowAddPayment(false)}
              >
                Close
              </Button>

              {/* <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }}
                className="shadow-md"
                style={{
                  padding: "10px 20px",
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                Add Payment
              </button> */}
            </div>
          </>
        )}
      </Formik>
    </div>
  );

  const _renderReschedule = () => (
    <div>
      <Formik
        onSubmit={Reschedule}
        validationSchema={formRescheduleValidations}
        enableReinitialize
        initialValues={{
          bookingDate: booking?.startDateTime
            ? Common.moment(booking.startDateTime).format(DateminFormat)
            : Common.moment().format(DateminFormat),
          courtId: booking?.courtId
            ? { value: booking.courtId, label: booking.courtName }
            : null,
          startDateTime: booking?.startDateTime
            ? Common.moment(booking.startDateTime).format("HH:mm")
            : "",
          endDateTime: booking?.endDateTime
            ? Common.moment(booking.endDateTime).format("HH:mm")
            : "",
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formikProps) => (
          <>
            <div className="mb-3">
              <FormikSearchSectionSelect
                formikProps={formikProps}
                name={"courtId"}
                label={"Court"}
                data={courtList}
              />
            </div>

            <div>
              <FormikDate
                formikProps={formikProps}
                name={"bookingDate"}
                type={"date"}
                label={"Booking Date"}
              />
            </div>

            <div className="md:grid md:grid-cols-2 gap-6 items-center mt-4">
              <div className="flex flex-col">
                <FormikInput
                  formikProps={formikProps}
                  name={"startDateTime"}
                  placeholder={"Start Time"}
                  label={"Start Time"}
                  type={"time"}
                  className="w-full"
                />
              </div>

              <div className="flex flex-col">
                <FormikInput
                  formikProps={formikProps}
                  name={"endDateTime"}
                  placeholder={"End Time"}
                  label={"End Time"}
                  type={"time"}
                  className="w-full"
                />
              </div>
            </div>

            <div style={{ marginTop: "20px", textAlign: "right" }}>
              <Button
                color="success"
                onClick={(e) => {
                  e.preventDefault();
                  formikProps.handleSubmit();
                }}
                className="shadow-md mr-3"
              >
                Reschedule
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );

  return (
    <>
      <div className="flex flex-wrap mb-3">
        <div className="text-xl font-medium text-default-900 flex-1">
          Ref # {booking?.refNo}
        </div>
        <div className="flex-none">
          <Breadcrumbs>
            <BreadcrumbItem
              onClick={() =>
                navigate(
                  global?.roleId == RoleEnum.Super_Admin
                    ? "/Booking"
                    : "/app/clubbookings"
                )
              }
            >
              Bookings
            </BreadcrumbItem>
            <BreadcrumbItem> Detail</BreadcrumbItem>
          </Breadcrumbs>
        </div>
      </div>

      {/* <div className="app-height flex gap-6 relative overflow-hidden">
                {isDesktop && showSidebar && (
                    <div
                        className=" bg-background/60 backdrop-filter
         backdrop-blur-sm absolute w-full flex-1 inset-0 z-[99] rounded-md"
                        onClick={() => setShowSidebar(false)}
                    ></div>
                )} */}

      <div className="app-heightx grid grid-cols-12 gap-6 relativex overflow-hiddenx">
        {/* <div
                    style={{ width: "350px" }}
                    className={cn("transition-all duration-150 flex-none  ", {
                        "absolute h-full top-0 md:w-[260px] w-[200px] z-[999]": isDesktop,
                        "flex-none min-w-[260px]": !isDesktop,
                        "left-0": isDesktop && showSidebar,
                        "-left-full": isDesktop && !showSidebar,
                    })}
                > */}

        <div className="col-span-12 md:col-span-4 ">
          {/* <Card className=" h-[600px] overflow-y-auto no-scrollbar"> */}
          <Card className="">
            <CardHeader className="stickyx top-0 mb-0 bg-card z-50">
              <p className="font-bold text-left text-xl">
                {booking?.club?.name}
              </p>
              {/* <p className="text-left text-xs">
                <Icon
                  icon={"heroicons:map"}
                  className={cn(
                    "w-4 h-4 float-left text-default-600 group-hover:text-primary",
                    ""
                  )}
                />
                {"  "}
                &nbsp; {booking?.club?.address}
              </p> */}
            </CardHeader>
            <CardContent className="h-full p-2">
              <BookingDetailSidebar
                setShowAddPayment={setShowAddPayment}
                setShowReschedule={setShowReschedule}
                booking={booking}
                getBookingDetails={getBookingDetails}
                onAdddNote={(param) => {
                  alert(param);
                }}
              />
            </CardContent>
          </Card>
        </div>

        <Card className="flex-1 col-span-12 md:col-span-8 overflow-x-auto">
          <BooingFurthurDetails
            booking={booking}
            openSheet={handleEditSheetOpen}
            handleSidebar={() => setShowSidebar(true)}
          />
        </Card>
      </div>
      <DialogModal
        title={"Add Payment"}
        open={showAddPayment}
        setOpen={setShowAddPayment}
        Child={_renderAddPayment}
      />

      <DialogModal
        title={"Reschedule"}
        open={showReschedule}
        setOpen={setShowReschedule}
        Child={_renderReschedule}
      />
    </>
  );
};

export default BooingDetails;
