import React from "react";
import { Formik } from "formik";
import makeAnimated from "react-select/animated";
import { components } from "react-select";
import { Icon } from "@iconify/react";

import * as yup from "yup";
import { useEffect, useRef, useState } from "react";
import { Button } from "../../../components/ui/button";
import Card from "../../../components/ui/card-snippet";
import Common from "../../../helper/common";
import {
    respEnum,
    SportFeatureTypeEnum,
    ToCSharpFormat,
    TournamentVenueTypeEnum,
    IntCourtPriceMin,
    IntMax,
} from "../../../constants/appConstant";
import {
    FormikInput,
    FormikMultiSelect,
    FormikSearchSelect,
    FormikSelect,
    FormikSingleFileUpload,
    FormikSwitch,
} from "../../../components/formik-components";
import { useLocation, useNavigate } from "react-router-dom";
import { AppInput } from "../../../components/appComponents";

const styles = {
    multiValue: (base, state) => {
        return state.data.isFixed ? { ...base, opacity: "0.5" } : base;
    },
    multiValueLabel: (base, state) => {
        return state.data.isFixed
            ? { ...base, color: "#626262", paddingRight: 6 }
            : base;
    },
    multiValueRemove: (base, state) => {
        return state.data.isFixed ? { ...base, display: "none" } : base;
    },
    option: (provided, state) => ({
        ...provided,
        fontSize: "14px",
    }),
};
const OptionComponent = ({ data, ...props }) => {
    //const Icon = data.icon;

    return (
        <components.Option {...props}>
            <div className="flex items-center gap-2">
                <Icon icon={data.icon} />
                {data.label}
            </div>
        </components.Option>
    );
};
const animatedComponents = makeAnimated();

const formValidations = yup.object({
    name: yup.string().required("Tournament Name is Required"),
    organizerName: yup.string().required("Organizer Name is Required"),
    venueType: yup.number().required("Venue Type is Required"),
    sportId: yup.number().required("Sport is Required"),
    sportFeatureId: yup.number().required("Sport Players is Required"),
    maxTeams: yup
        .number()
        .min(4)
        .max(IntCourtPriceMin)
        .required("Max-Team / Participants is Required"),
    entryFee: yup.number().min(IntCourtPriceMin).max(IntMax).required("Entry Fee is Requied"),
    group: yup.number().min(2).max(IntCourtPriceMin).required("Groups is Required"),
    organizerPhoneNo: yup
        .string()
        .nullable()
        .matches(/^\d{3}-\d{7}$/, "Phone must be in the format 301-2345678")
        .notRequired(),

    registrationStartDateTime: yup
        .date()
        .required("Registration start date and time is required")
        .test(
            "is-valid-time",
            "Time of the Registration start date format should be in 00, 30 or 23:59 format",
            (value) => Common.Utility.checkValidTimeOfDate(value)),

    registrationEndDateTime: yup
        .date()
        .min(new Date(), "Registration start date cannot be in the past")
        .required("Registration end date and time is required")
        .test(
            "is-valid-time",
            "Time of the Registration end date format should be in 00, 30 or 23:59 format",
            (value) => Common.Utility.checkValidTimeOfDate(value))
        .test(
            "is-future-time",
            "Time of the Registration end date cannot be in the past",
            Common.Utility.checkPreviousTimeOfDate
        ).test(
        "is-after-Registrationstart-time",
        "Registration End time must be after the start time",
        function (endDate) {
            const { registrationStartDateTime } = this.parent;
            return !registrationStartDateTime || !endDate || new Date(endDate) > new Date(registrationStartDateTime);
        }
    ),

    startDateTime: yup.date().required("Tournament Start time is required")
        .min(new Date(), "Tournament start date cannot be in the past")
        .test(
        "is-valid-time",
        "Time of the Tournament start date format should be in 00, 30 or 23:59 format",
        (value) => Common.Utility.checkValidTimeOfDate(value))
        .test(
            "is-future-time",
            "Time of the Tournament start date cannot be in the past",
            Common.Utility.checkPreviousTimeOfDate
        ),

    endDateTime: yup.date().required("Tournament End time is required")
        .min(new Date(), "Tournament end date cannot be in the past")
        .test(
        "is-valid-time",
        "Time of the Tournament end date format should be in 00, 30 or 23:59 format",
        (value) => Common.Utility.checkValidTimeOfDate(value))
        .test(
            "is-future-time",
            "Time of the Tournament end date cannot be in the past",
            Common.Utility.checkPreviousTimeOfDate
    ).test(
        "is-after-start-time",
        "Tournament End time must be after the start time",
        function (endDate) {
            const { startDateTime } = this.parent;
            return !startDateTime || !endDate || new Date(endDate) > new Date(startDateTime);
        }
    ),

});

export default function AddTournament() {
    const [sports, setSports] = useState([]);
    const [sportplayers, setSportPlayers] = useState([]);
    const [cities, setCities] = useState([]);
    const [id, setId] = useState();
    const [clubs, setClubs] = useState([]);
    const [venueList, setVenueList] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const formikRef = useRef();

    const [prizes, setPrizes] = useState([
        { position: 1, title: "Winner", points: "", winingPrize: "" },
        { position: 2, title: "Runner Up", points: "", winingPrize: "" },
        { position: 3, title: "Participants", points: "", winingPrize: "" },
    ]);

    const formInitialValue = {};

    useEffect(() => {
        const fetchData = async () => {
            getSports(); // Fetch sports
            await Common.delay();
            getCities(); // Fetch cities
            await Common.delay();
            getClubs(); // Fetch clubs
        };

        fetchData();
    }, []);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const fetchedId = params.get("id");

        if (fetchedId) {
            setId(fetchedId);
            GetTournamentDetails(fetchedId);
        }
    }, [sports]);

    const onSportChange = async (val, formikProps) => {
        if (val) {
            let _sport = sports.find((item) => item.id == val);
            const players =
                _sport?.sportFeatures
                    .filter((item) => item.type == SportFeatureTypeEnum.No_of_Players)
                    .map((item) => ({
                        id: item.id,
                        name: `${item.name} (${item.noofplayers} vs ${item.noofplayers})`,
                    })) ?? [];

            setSportPlayers([{ id: null, name: "Select Players" }, ...players]);
        }
        formikProps.setFieldValue("sportFeatureId", null);
    };

    const GetTournamentDetails = async (id) => {
        let response = await Common.ApiService.getInstance().request(
            `GetTournamentDetails?id=${id}`
        );

        let d = response?.data?.[0];

        if (d && formikRef.current) {
            formikRef.current.setFieldValue("sportId", d.sportId);
            formikRef.current.setFieldValue("venueType", d.venueType);
            onVenueTypeChange(d.venueType, formikRef.current);
            onSportChange(d.sportId, formikRef.current);
            await Common.delay();
        }

        if (formikRef.current) {
            formikRef.current.setValues(d);
        }

        updatePrizes(d.prizes);
    };

    const getSports = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetSports"
            );
            const data = [
                { id: null, name: "Select Sport" },
                ...(response?.data ?? []),
            ];

            setSports(data);
        });
    };

    const getCities = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetCities"
            );
            const data = [...(response?.data ?? [])];

            // { id: null, name: "Select Cities" },
            setCities(data);
        });
    };

    const updatePrizes = (data) => {
        // Create a lookup table for incoming data based on position
        const dataMap = data.reduce((acc, item) => {
            acc[item.position] = item;
            return acc;
        }, {});

        // Map over the existing prizes and update values based on position
        const updatedPrizes = prizes.map((prize) => {
            const matchingData = dataMap[prize.position];
            if (matchingData) {
                return {
                    ...prize,
                    points: matchingData.points,
                    winingPrize: matchingData.winingPrize,
                };
            }
            return prize;
        });

        // Directly update state without returning anything
        setPrizes(updatedPrizes);
    };

    // No return needed here since you're directly modifying the state

    const getClubs = async () => {
        return new Promise(async (res) => {
            const response = await Common.ApiService.getInstance().request(
                "GetClubsDDL"
            );
            const data = [...(response?.data ?? [])];
            // { id: null, name: "Select Clubs" },
            setClubs(data);
        });
    };

    const handleInputChange = (index, field, value) => {
        const updatedPrizes = [...prizes];
        updatedPrizes[index][field] = value;
        setPrizes(updatedPrizes);
    };

    const onVenueTypeChange = (val, formikProps) => {
        let data;
        if (val == TournamentVenueTypeEnum.City_Wise) {
            data = cities.map((item) => ({ label: item.name, value: item.id }));
        } else if (val == TournamentVenueTypeEnum.Club_Wise) {
            data = clubs.map((item) => ({ label: item.name, value: item.id }));
        }
        formikProps.setFieldValue("venues", []);
        setVenueList(data);
    };

    const onSubmitData = async (d) => {
        // Validate venues
        if (!d.venues || d.venues.length === 0) {
            Common.showToast("Select Venues");
            return;
        }

        // Map venues based on the venue type
        if (d.venueType == TournamentVenueTypeEnum.City_Wise) {
            d.venues = cities.map((item) => ({ cityId: item.id }));
        } else if (d.venueType == TournamentVenueTypeEnum.Club_Wise) {
            d.venues = clubs.map((item) => ({ clubId: item.id }));
        }

        // Validate prizes
        if (!prizes || prizes.length === 0) {
            Common.showToast("Select Prizing");
            return;
        }

        // Prize validation logic
        for (let i = 0; i < prizes.length; i++) {
            const prize = prizes[i];

            // Check if title is missing
            if (!prize.title) {
                Common.showToast(`Select title for position ${prize.position}`);
                return;
            }

            // Check if points are empty or not a valid number
            if (!prize.points || isNaN(parseInt(prize.points))) {
                Common.showToast(`Select valid points for position ${prize.position}`);
                return;
            }

            // Convert points to integer
            prize.points = parseInt(prize.points);

            // Check if winingPrize is empty or not a valid number
            if (!prize.winingPrize || isNaN(parseInt(prize.winingPrize))) {
                Common.showToast(
                    `Select valid winning prize for position ${prize.position}`
                );
                return;
            }

            // Convert winingPrize to integer
            prize.winingPrize = parseInt(prize.winingPrize);
        }

        // // Parse groups and maxTeams as integers
        // const groups = parseInt(d.groups);
        // const maxTeams = parseInt(d.maxTeams);

        // // Validate groups
        // if (isNaN(groups) || groups <= 0) {
        //   Common.showToast("Number of groups must be greater than 0.");
        //   return;
        // }

        // // Validate maxTeams
        // if (isNaN(maxTeams) || maxTeams <= 0) {
        //   Common.showToast("Number of teams must be greater than 0.");
        //   return;
        // }

        // // Check if maxTeams can be evenly divided into groups
        // if (maxTeams % groups !== 0) {
        //   Common.showToast(`Teams cannot be evenly distributed across ${groups} groups.`);
        //   return;
        // }

        if (!d.files && !d.icon) {
            Common.showToast("Select file");
            return;
        }
        if (d.files) {
            d.icon = (await Common.Utility.handleFileUpload(d.files))[0].photo;
            d.files = null;
        }

        let data = {
            ...d,
            id,
            maxTeams: +d.maxTeams,
            entryFee: +d.entryFee,
            group: +d.group,
            prizes,
            registrationStartDateTime: Common.moment(
                `${d.registrationStartDateTime}`
            ).format(ToCSharpFormat),
            registrationEndDateTime: Common.moment(
                `${d.registrationEndDateTime}`
            ).format(ToCSharpFormat),
            startDateTime: Common.moment(`${d.startDateTime}`).format(ToCSharpFormat),
            endDateTime: Common.moment(`${d.endDateTime}`).format(ToCSharpFormat),
        };

        let response = await Common.ApiService.getInstance().request(
            "AddUpdateTournament",
            data,
            "POST"
        );
        if (response.status == respEnum.Success) {
            Common.SweetAlert.alert(response.message);
            setTimeout(() => {
                navigate("/app/tournaments");
            }, 1000);
        }
    };

    return (
        <Formik
            initialValues={formInitialValue}
            validationSchema={formValidations}
            innerRef={formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            onSubmit={onSubmitData}
        >
            {(formikProps) => {
                formikRef.current = formikProps;
                return (
                    <>
                        <div className="space-y-6 mb-5">
                            <Card title="Create Tournament">
                                <div className="md:grid md:grid-cols-3  gap-6">
                                    <div className="flex flex-col col-span-3">
                                        <div className="md:grid md:grid-cols-3  gap-6 ">
                                            <div className="flex flex-col  col-span-1">
                                                <div className="md:grid md:grid-cols-4  gap-6 space-y-6 md:space-y-0">
                                                    <div className="flex flex-col col-span-3 gap-2">
                                                        <FormikInput
                                                            formikProps={formikProps}
                                                            name={"name"}
                                                            placeholder={"Tournament Name"}
                                                            label={"Tournament Name"}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col col-span-1 gap-2">
                                                        <FormikSwitch
                                                            formikProps={formikProps}
                                                            name={"isTeamOnly"}
                                                            label={"TeamOnly"}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col col-span-4 gap-2">
                                                        <FormikSingleFileUpload
                                                            formikProps={formikProps}
                                                            uploadMainTxt={"Upload Logo"}
                                                            uploadTxt={"Max size 2mb"}
                                                            name={"icon"}
                                                        // data={customerList}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-col col-span-2">
                                                <div className="md:grid md:grid-cols-4  gap-2 space-y-6 md:space-y-0">                                                    

                                                    <div className="flex flex-col  col-span-1 gap-1">
                                                        <FormikSearchSelect
                                                            formikProps={formikProps}
                                                            name={"sportId"}
                                                            placeholder={"Select Sport"}
                                                            label={"Sport"}
                                                            data={sports}
                                                            onChange={(val) =>
                                                                onSportChange(val, formikProps)
                                                            }
                                                        />
                                                    </div>

                                                    <div className="flex flex-col  col-span-1 gap-1">
                                                        <FormikSelect
                                                            formikProps={formikProps}
                                                            name={"sportFeatureId"}
                                                            data={sportplayers}
                                                            placeholder={"Sport Players"}
                                                            label={"Player"}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col  col-span-1 gap-1">
                                                        <FormikSelect
                                                            formikProps={formikProps}
                                                            name={"venueType"}
                                                            data={[
                                                                { id: null, name: "Select" },
                                                                ...Common.Utility.enumToArray(
                                                                    TournamentVenueTypeEnum
                                                                ),
                                                            ]}
                                                            placeholder={"Type"}
                                                            label={"Venue Type"}
                                                            onChange={(val) =>
                                                                onVenueTypeChange(val, formikProps)
                                                            }
                                                        />
                                                    </div>

                                                    <div className="flex flex-col col-span-1 gap-1">
                                                        <FormikInput
                                                            type={"number"}
                                                            formikProps={formikProps}
                                                            name={"maxTeams"}
                                                            placeholder={"Participants / Max-Team"}
                                                            label={"Participants / Max-Team"}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col col-span-1 gap-1">
                                                        <FormikInput
                                                            type={"number"}
                                                            formikProps={formikProps}
                                                            name={"group"}
                                                            placeholder={"Total Group"}
                                                            label={"Groups"}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col  col-span-1 gap-1">
                                                        <FormikInput
                                                            formikProps={formikProps}
                                                            name={"entryFee"}
                                                            type={"number"}
                                                            placeholder={"Entry Fee"}
                                                            label={"Entry Fee"}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col  col-span-1 gap-1">
                                                        <FormikInput
                                                            formikProps={formikProps}
                                                            name={"organizerName"}
                                                            placeholder={"Organizer Name"}
                                                            label={"Organizer Name"}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col  col-span-1 gap-1">
                                                        <FormikInput
                                                            formikProps={formikProps}
                                                            type={"number"}
                                                            name={"organizerPhoneNo"}
                                                            placeholder={"Organizer Number"}
                                                            label={"Organizer Number"}
                                                        />
                                                    </div>

                                                    <div className="flex flex-col  col-span-2">
                                                        <div className="md:grid md:grid-cols-4  gap-2 space-y-6 md:space-y-0">
                                                            <div className="flex flex-col  col-span-4">
                                                                <hr class="my-1"></hr>
                                                                <p className="font-bold text-left text-sm">
                                                                    Registration Dates
                                                                </p>
                                                            </div>

                                                            <div className="flex flex-col  col-span-2 gap-1">
                                                                <FormikInput
                                                                    formikProps={formikProps}
                                                                    name={"registrationStartDateTime"}
                                                                    placeholder={"Start Date & Time"}
                                                                    label={"Start"}
                                                                    type={"datetime-local"}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col col-span-2 gap-3">
                                                                <FormikInput
                                                                    formikProps={formikProps}
                                                                    name={"registrationEndDateTime"}
                                                                    placeholder={"End Date & Time"}
                                                                    label={"End"}
                                                                    type={"datetime-local"}
                                                                    min={formikProps.values.registrationStartDateTime || Common.Utility.todayDatetime}
                                                                />
                                                            </div>

                                                            <div className="flex flex-col  col-span-4">
                                                                <hr class="my-1"></hr>
                                                                <p className="font-bold text-left text-sm">
                                                                    League Start
                                                                </p>
                                                            </div>
                                                            <div className="col-span-2">
                                                                <FormikInput
                                                                    formikProps={formikProps}
                                                                    name={"startDateTime"}
                                                                    placeholder={"Start Date & Time"}
                                                                    label={"Start"}
                                                                    type={"datetime-local"}
                                                                    min={Common.Utility.todayDatetime}
                                                                />
                                                            </div>
                                                            <div className="col-span-2">
                                                                <FormikInput
                                                                    formikProps={formikProps}
                                                                    name={"endDateTime"}
                                                                    placeholder={"Start Date & Time"}
                                                                    label={"End "}
                                                                    type={"datetime-local"}
                                                                    min={formikProps.values.startDateTime || Common.Utility.todayDatetime}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col  col-span-2">
                                                        <div className="md:grid md:grid-cols-7 gap-2 space-y-6 md:space-y-0">
                                                            <div className="flex flex-col col-span-7">
                                                                <hr className="my-1" />
                                                                <p className="font-bold text-left text-sm">
                                                                    Add Winning Price and Points
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-col col-span-1">
                                                                <p className="font-bold text-left text-sm">
                                                                    Position
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-col col-span-2">
                                                                <p className="font-bold text-center text-sm">
                                                                    Title
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-col col-span-2">
                                                                <p className="font-bold text-left text-sm">
                                                                    Wining Price
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-col col-span-2">
                                                                <p className="font-bold text-left text-sm">
                                                                    Points
                                                                </p>
                                                            </div>

                                                            {prizes.map((prize, index) => (
                                                                <React.Fragment key={index}>
                                                                    <div className="col-span-1 pt-2 text-center">
                                                                        {prize.position}
                                                                    </div>

                                                                    <div className="col-span-2">
                                                                        <AppInput
                                                                            type={"text"}
                                                                            readOnly
                                                                            value={prize.title}
                                                                            placeholder="Title"
                                                                            onChange={(value) =>
                                                                                handleInputChange(index, "title", value)
                                                                            }
                                                                            className="form-input"
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-2">
                                                                        <AppInput
                                                                            type={"number"}
                                                                            value={prize.winingPrize}
                                                                            onChange={(value) =>
                                                                                handleInputChange(
                                                                                    index,
                                                                                    "winingPrize",
                                                                                    value
                                                                                )
                                                                            }
                                                                            placeholder="Winning Price"
                                                                            className="form-input"
                                                                        />
                                                                    </div>
                                                                    <div className="col-span-2">
                                                                        <AppInput
                                                                            type={"number"}
                                                                            value={prize.points}
                                                                            onChange={(value) =>
                                                                                handleInputChange(
                                                                                    index,
                                                                                    "points",
                                                                                    value
                                                                                )
                                                                            }
                                                                            placeholder="Points"
                                                                            className="form-input"
                                                                        />
                                                                    </div>
                                                                </React.Fragment>
                                                            ))}
                                                            <div className="flex flex-col col-span-1 gap-2">
                                                                <FormikSwitch
                                                                    formikProps={formikProps}
                                                                    name={"isGrouped"}
                                                                    label={"Grouped"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col mt-5 col-span-2">
                                                        <FormikMultiSelect
                                                            animatedComponents={animatedComponents}
                                                            data={venueList}
                                                            formikProps={formikProps}
                                                            name={"venues"}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>

                        <div className="flex flex-col items-end mt-5">
                            <Button
                                disabled={formikProps.isSubmitting}
                                onClick={formikProps.handleSubmit} // Use handleSubmit directly
                                type="submit" // Set type to submit to ensure default form behavior
                                className="mt-7 btn-sm"
                            >
                                Save
                            </Button>
                        </div>
                    </>
                );
            }}
        </Formik>
    );
}
