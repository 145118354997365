import React, { useState, useCallback, useRef, useEffect } from "react";
import { GoogleMap, Marker, useLoadScript, Autocomplete } from "@react-google-maps/api";

const containerStyle = {
    width: "100%",
    height: "400px",
};

const GoogleMapCustom = ({ defaultLat, defaultLng, onLocationChange, isReinit }) => {

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyAE6MQgx8MwLZCVOp7Qb11j4zG6hd9YikM", // Replace with your API Key
        libraries: ["places"], // Load Places library
    });

    if (isReinit) {
        alert("hello");
    }

    const [markerPosition, setMarkerPosition] = useState({ lat: defaultLat, lng: defaultLng });
    const [mapCenter, setMapCenter] = useState({ lat: defaultLat, lng: defaultLng });
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (onLocationChange) {
            onLocationChange(markerPosition);
        }
    }, [markerPosition, onLocationChange]);

    const handleMarkerDragEnd = useCallback((event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
    }, []);

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry) {
            const newCenter = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            setMapCenter(newCenter);
            setMarkerPosition(newCenter);
        }
    };

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            {/* Search Box */}
            <Autocomplete
                onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                onPlaceChanged={handlePlaceChanged}
            >
                <input
                    type="text"
                    placeholder="Search for a location"
                    style={{
                        width: "100%",
                        padding: "10px",
                        marginBottom: "10px",
                        fontSize: "16px",
                        border: "1px solid #ccc",
                        borderRadius: "6px",
                    }}
                />
            </Autocomplete>

            {/* Google Map */}
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={mapCenter}
                zoom={14}
            >
                <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                />
            </GoogleMap>

            {/* Display Coordinates */}
            <p className="mt-4">
                <strong>Selected Coordinates:</strong> Latitude: {markerPosition.lat}, Longitude: {markerPosition.lng}
            </p>
        </div>
    );
};

export default GoogleMapCustom;
